@import "../../styles/variables";

.coloured-footer {
  background-color: var(--bs-footer-background-color);
  padding: 60px 0;

  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .footer-list-title {
    margin-bottom: 0;
    button {
      color: $textBlack;
    }
  }

  .accordion-button {
    padding-left: 0;
    padding-right: 0;

    &:focus {
      border: none;
      box-shadow: none;
    }

    &:not(.collapsed) {
      background-color: var(--bs-accordion-btn-bg);
    }
  }
  .accordion-item {
    background-color: var(--bs-accordion-btn-bg);
    border: unset;

    .accordion-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 992px) {
  .coloured-footer {
    .footer-list-title {
      border-bottom: 1px solid var(--bs-green);
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;

      button {
        pointer-events: none;
        &:after {
          display: none;
        }
      }
    }

    .accordion-button {
      &:not(.collapsed) {
        box-shadow: none;
      }
    }
  }
}
