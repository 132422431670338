@import "../../styles/variables";
h3 {
  color: $green;
  margin-bottom: 30px;
}

.container {
  &.full-width {
    width: 100%;

    > div {
      width: 70%;
      margin: 0 auto;
    }
  }
}

@media (min-width: 992px) {
  .main-container {
    .small-container {
      > div {
        width: 70%;
        margin: 0 auto;
      }
    }
    .full-width {
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }
}
