.form-control {
  &:focus {
    border-color: #141414;
    border-width: 2px;
    box-shadow: none;
  }
}

.form-label {
    margin-bottom: 10px;
}