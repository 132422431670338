@import "../../styles/variables";
h4 {
  font-weight: 600;
}

.main-container {
  padding: 3.75rem 1rem;
}

@media (min-width: 992px) {
  .main-container {
    padding: 3.75rem 0;
  }

  .body-text {
    width: 70%;
  }
}
.media-container {
  .coloured-blocks {
    padding: 2rem;

    margin-right: 0;
    margin-left: 0;
    justify-content: center;

    .col-12 {
      padding-left: 0;
      padding-right: 0;
    }

    .col-12,
    .col-lg-4 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      &:nth-of-type(1) {
        .block {
          background-color: #b7e0cb4d;
        }
      }
      &:nth-of-type(2) {
        .block {
          background-color: #b7e0cb99;
        }
      }
      &:nth-of-type(3) {
        .block {
          background-color: $lightGreen;
        }
      }
      .block {
        padding: 3rem;
        height: 100%;
        width: 100%;

        > div {
          &:not(:last-of-type) {
            padding-bottom: 3rem;
          }
        }
      }

      h3 {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .media-container {
    .coloured-blocks {
      margin: 0 auto;
      flex-wrap: nowrap;

      .col-lg-6 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

@media (min-width: 1200px) {
  .media-container {
    .coloured-blocks {
      width: 75%;
    }
  }
}
