@import "../../../styles/variables";
.radio {
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
  border: var(--bs-border-width) solid var(--bs-border-color);
  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + label {
      display: flex;
      align-items: center;
      padding: 0.5rem 0 0.5rem 1rem;

      &:before {
        content: "";
        background: $white;
        border-radius: 100%;
        border: 1px solid $textBlack;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + label {
        &:before {
          background-color: $green;
          box-shadow: inset 0 0 0 4px $white;
        }
      }
    }
    &:focus {
      + label {
        &:before {
          outline: none;
          border-color: $textBlack;
        }
      }
    }

    + label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
