@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./styles/typography";
@import "./styles/variables";
:root {
  --bs-body-font-family: "GillSansNovaforJLVar", sans-serif;
  --bs-green: #003936;
  --bs-success: #099e3d;
  --bs-info: #4891d6;
  --bs-warning: #ffb017;
  --bs-danger: #cc1426;
  --bs-orange: #f1b472;
  --bs-body-color: #141414;
  --bs-border-radius: 2;
  --bs-border-color: #7f7f7f;
  --bs-heading-color: var(--bs-green);
  --bs-link-color-rgb: var(--bs-body-color);
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}

.form-check-input {
  &[type="checkbox"] {
    border-radius: 1px;
  }
  &:focus {
    box-shadow: none;
  }
}
.btn {
  --bs-btn-hover-bg: #265655;
  --bs-btn-hover-border-color: #265655;
  --bs-btn-disabled-bg: #265655;
  --bs-btn-disabled-border-color: #265655;
}

.navbar {
  --bs-navbar-toggler-border-color: var(--bs-green);
  --bs-navbar-active-color: var(--bs-orange);
}

.nav-link {
  --bs-nav-link-color: #5b5b5b;
  --bs-nav-tabs-link-active-color: var(--bs-body-color);
  --bs-nav-tabs-link-active-border-color: var(--bs-body-color);
  --bs-nav-tabs-link-hover-border-color: transparent;
  --bs-nav-tabs-link-active-bg: #f3f3f3;
  margin-bottom: 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom-color: var(--bs-body-color);

  &.active {
    border-bottom: 2px var(--bs-body-color) solid;
    font-weight: 700;
  }

  &:focus-visible {
   box-shadow: none;
  }

  
}

.nav-tabs {
  .nav-item {
    button {
      &:hover, &:focus {
        --bs-nav-link-hover-color: #003936;
      }
    }
  }
}

.btn-primary {
  --bs-btn-color: var(--bs-orange);
  --bs-btn-bg: var(--bs-green);
  --bs-btn-border-color: var(--bs-green);
  --bs-btn-border-radius: 2px;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-padding-y: 10px;
}

footer {
  --bs-footer-background-color: #ececec;
}

.tab-content {
  margin-top: 2rem;
}

.nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  border-bottom: none;

  &.nav-fill {
    .nav-item {
      border-bottom: 2px solid #14141414;
      padding-bottom: 0;
      white-space: nowrap;

      button {
        font-size: 1.25rem;
      }
    }
  }
}

.accordion {
  --bs-accordion-btn-bg: transparent;
}
ul {
  list-style-type: none;
  padding-left: 0;
  li {
    padding-bottom: 1rem;
    a {
      text-decoration: none;
      color: var(--bs-body-color);
    }
  }
}

p {
  margin-bottom: 0.25rem;
}

ul {
  margin-bottom: 0;
}

ul li {
  padding-bottom: 0.25rem;
}
.body-text {
  margin: 0 auto;
}

.formGroup {
  margin-bottom: 30px;

  a {
    --bs-link-color-rgb: #003936;
  }
}

.form-check-input {
  &:checked {
    background-color: #003936;
    border-color: #003936;
  }
}

.form-background {
  background-color: #f3f3f3;
  .radio {
    background-color: #ffffff;
  }
}

.main-container {
  padding: 3.75rem 1rem;
}

@media (min-width: 992px) {
  .main-container {
    padding: 3.75rem 0;
  }

  .body-text {
    width: 70%;
  }
}
