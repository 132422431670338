.coloured-nav {
  background-color: var(--bs-green);
  padding: 30px 0;

  img {
    width: 60%;
  }
  .nav-row {
    margin-left: 0;
    margin-right: 0;
    .navbar-nav {
      a {
        &.active {
          border-bottom: none;
          font-weight: normal;
        }
      }
    }
  }

  button {
    position: absolute;
    top: 25px;
    right: 10px;
  }

  align-items: flex-start;
  a {
    color: var(--bs-white);
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .coloured-nav {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    flex-direction: column;

    a {
      color: var(--bs-white);
      padding-bottom: 1.5rem;

      &.logo {
        padding-bottom: 0;
      }

      &.active,
      &:hover {
        padding-bottom: 0;
        span {
          border-bottom: 4px solid var(--bs-orange);
          padding-bottom: 1.45rem;
        }
      }
    }
    img {
      height: 150px;
      width: auto;
    }
  }
}
