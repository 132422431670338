@import "../../../styles/variables";

.mainContainer {
  padding: 3.75rem 1rem;

  .row {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .introContainer {
      padding: 20px;
      text-align: center;
      border: 3px solid rgb(101, 141, 27);
      flex: 1;
      width: 100%;
      &:nth-child(2) {
        margin: 1rem 0;
      }

      h1 {
        font-size: 2rem;
        text-transform: uppercase;
      }
    }

    .mediaBlock {
      padding: 2rem;
      &.imageContainer {
        background-color: $green;
      }

      &.textContainer {
        background-color: $grey10;
      }
    }
  }
}

// .block {
//   background-color: #b7e0cb4d;
//   p {
//     margin-bottom: 0;
//   }
// }

// .info-row {
//   .col-lg-3 {
//     display: flex;
//     justify-content: center;
//   }
//   .info-container {
//     width: 80%;
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     .img-container {
//       display: flex;
//       justify-content: center;
//     }
//   }
// }

// .block-container {
//   position: relative;

//   .arrow {
//     width: 5px;
//     height: 95%;
//     background: #003936;
//     position: absolute;
//     left: 50%;
//     margin-left: -2px;

//     &:after {
//       transform: rotate(45deg);
//       border: solid #003936;
//       border-width: 0 5px 5px 0;
//       height: 25px;
//       width: 25px;
//       content: "";
//       left: -10px;
//       bottom: 0;
//       position: absolute;
//     }
//   }
//   .block {
//     background-color: #b7e0cb4d;

//     .container {
//       display: flex;

//       .col-6 {
//         &:first-of-type {
//           padding-right: 2rem;
//         }
//       }

//       h3 {
//         text-align: right;
//         margin-top: 1.5rem;
//         font-size: 1.25rem;
//         font-weight: 600;
//       }
//       ul {
//         margin-bottom: 0;
//         display: flex;
//         flex-direction: column;

//         li {
//           display: flex;
//           align-items: center;
//           flex-wrap: wrap;
//           padding-left: 1rem;

//           p {
//             padding-left: 1rem;
//           }
//           span {
//             width: 100%;
//           }
//         }
//       }
//     }
//     &.block-2 {
//       background-color: #b7e0cb99;
//     }

//     &.block-3 {
//       background-color: $lightGreen;
//     }
//   }
// }

@media (min-width: 992px) {
  .mainContainer {
    .row {
      flex-direction: row;
      .introContainer {
        width: calc(100% / 3 - 2rem);
        &:nth-child(2) {
          margin: 0 1rem;
        }
      }
    }
  }
}

// @media (min-width: 1200px) {
//   .block-container {
//     .left-triangle {
//       border-width: 0 0 1000px 400px;
//     }

//     .right-triangle {
//       border-width: 1000px 0 0 400px;
//     }
//   }
// }
