@import "../../styles/variables";

.creative-block {
  margin-left: -1rem;
  margin-right: -1rem;

  .col-12 {
    padding-left: 0;
    padding-right: 0;
  }

  .text-block {
    background-color: $grey5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    h3 {
      color: $green;
      padding-top: 3.75rem;
      margin-bottom: 0;
    }
    p {
      padding-top: 1.875rem;
      padding-bottom: 3.75rem;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .creative-block {
    &:nth-child(even) {
      .col-lg-6:first-of-type {
        order: 1;
      }
    }

    .col-lg-4 {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    .text-block {
      padding-left: 3.75rem;
      padding-right: 3.75rem;
    }
  }
}
