@import "../../../styles/variables";
.media-container {
  .block {
    background-color: #b7e0cb4d;
    p {
      margin-bottom: 0;
    }
  }

  .info-row {
    .col-lg-3 {
      display: flex;
      justify-content: center;
    }
    .info-container {
      width: 80%;
      display: flex;
      align-items: center;
      flex-direction: column;
    
      .img-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  .block-container {
    position: relative;

    .arrow {
      width: 5px;
      height: 95%;
      background: #003936;
      position: absolute;
      left: 50%;
      margin-left: -2px;

      &:after {
        transform: rotate(45deg);
        border: solid #003936;
        border-width: 0 5px 5px 0;
        height: 25px;
        width: 25px;
        content: "";
        left: -10px;
        bottom: 0;
        position: absolute;
      }
    }
    .block {
      background-color: #b7e0cb4d;

      .container {
        display: flex;

        .col-6 {
          &:first-of-type {
            padding-right: 2rem;
          }
        }

        h3 {
          text-align: right;
          margin-top: 1.5rem;
          font-size: 1.25rem;
          font-weight: 600;
        }
        ul {
          margin-bottom: 0;
          display: flex;
          flex-direction: column;

          li {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-left: 1rem;
            padding-bottom: 1rem;

            p {
              padding-left: 0.7rem;
              margin-top: -15px;
            }
            span {
              width: 100%;
            }
          }
        }
      }
      &.block-2 {
        background-color: #b7e0cb99;
      }

      &.block-3 {
        background-color: $lightGreen;
      }
    }
  }
}

@media (min-width: 992px) {
  .media-container {
    .customers {

      .info-row {
        .col-lg-3 {
          display: flex;
          justify-content: center;
        }
        .info-container {
          width: 80%;
          display: flex;
          align-items: center;
          flex-direction: column;
          .img-container {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .block-container {
      .left-triangle {
        width: 0px;
        height: 0px;
        float: right;
        border-style: solid;
        border-width: 0 0 1000px 175px;
        border-color: transparent transparent white transparent;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .right-triangle {
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 1000px 0 0 175px;
        border-color: transparent transparent transparent white;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      .block {
        .container {
          h3 {
            font-size: 2rem;
            font-weight: unset;
          }
          ul {
            li {
              p {
                margin-top: 0;
              }
              span {
                width: unset;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .media-container {
    .block-container {
      .left-triangle {
        border-width: 0 0 1000px 400px;
      }
  
      .right-triangle {
        border-width: 1000px 0 0 400px;
      }
    }
  }
 
}
