@import "../../styles/variables";
@import "../../styles/mixins/list";
.row {
  .mediaBlock {
    padding: 1rem;
    &.imageContainer {
      background-color: $green;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.textContainer {
      padding: 1rem;
      h2 {
        text-transform: uppercase;
        letter-spacing: 5px;
        font-size: 1.5rem;
      }
      background-color: $grey10;

      .styledList {
        @include list;

        .subpointList {
          margin-left: 1rem;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .row {
    .mediaBlock {
      padding: 2rem;

      &.textContainer {
        padding: 2rem;
      }
    }

    &:nth-child(even) {
      .mediaBlock:first-of-type {
        order: 1;
      }
    }
  }
}
