.insights-page {
  .styled-list {
    list-style-position: inside;
    li {
      padding-bottom: 0;
      list-style-type: disc;
      font-size: 1.25rem;
    }
  }

  .body-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text-container {
    margin-bottom: 2rem;
  }
  .dunnhumby-logo {
    width: 75%;
    padding-bottom: 2rem;
  }
}
