$green: #003936;
$midGreen: #05B084;
$lightGreen: #B7E0CB;

$orange: #F1B472;
$darkOrange: #EE8C2D;
$lightOrange: #F6DECA;

$white: #FFFFFF;

$trueBlack: #000000;
$textBlack: #141414;

$grey83: #2B2B2B;
$grey64: #5B5B5B;
$grey56: #6F6F6F;
$grey20: #CCCCCC;
$grey15: #D8D8D8;
$grey10: #ECECEC;
$grey5: #F3F3F3;


$ep100: #CC1426;
$ep40: #F0B9BE;
$ep10: #FBECEE;

// Success
$success100: #099E3D;
$success40: #B5E2C5;
$success10: #EBF7EF;

// Warning 
$warning100: #FFB017;
$warning40: #FFE7B9;
$warning10: #FFF9EC;

// Information
$information100: #4891D6;
$information40: #C8DEF3;
$information10: #F1F7FC;
