  h1,
  .heading-xxl {
    font-size: 6rem;
    font-weight: 400;
  }

  h2,
  .heading-xl {
    font-size: 3rem;
    font-weight: 400;
  }

  h3,
  .heading-l,
  .subtitle-l {
    font-size: 2rem;
    font-weight: 400;
  }

  h4,
  .heading-m,
  .subtitle-m {
    font-size: 1.5rem !important;
    font-weight: 400;
  }

  h5,
  .heading-s,
  .body-l {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .heading-bold {
    font-weight: 700;
  }

  h6,
  .heading-xs,
  .body-m {
    font-size: 1rem;
    font-weight: 400;
  }

  .small-print-headline {
    font-size: 0.875rem;
    font-weight: bold;
  }

  .small-print-body {
    font-size: 0.875rem;
  }
