@import "../../styles/variables";

.hero {
  height: 400px;
  background-repeat: no-repeat;
  background-position: -55px 112%;
  background-size: 250px;

  &.john-lewis {
    background-image: url("../../../public/assets/photography/john_lewis_mobile.svg") !important;
  }

  &.waitrose {
    background-image: url("../../../public/assets/photography/waitrose_mobile.svg") !important;

  }

  .overlay-logo {
    width: 100%;
  }

  &.full-width-background {
    background-size: cover;
    background-position: center;
  }

  .mobile-centered {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      margin-top: 0;
    }
  }

  h2 {
    margin-top: 4.125rem;
  }
}

@media (min-width: 992px) {
  .hero {
    height: 250px;
    align-items: center;
    background-position: -55px 30px;

    &.john-lewis {
      background-image: url("../../../public/assets/photography/john_lewis.png") !important;
    }

    &.waitrose {
      background-image: url('../../../public/assets/photography/WAITROSE.png');
    }

    .overlay-logo {
      width: 70%;
    }

    h2 {
      margin: 0;
    }
  }
}
