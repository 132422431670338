.team-page {
  .nav-tabs .nav-link {
    &.active {
      background-color: transparent;
    }
  }

  .body-text {
    margin-bottom: 3.75rem;
  }
  p {
    &:not(.body-text) {
      margin-bottom: 0;
    }
  }
}
