@import "../../../styles/mixins/list";
.tab-container {
  .tab-content {
    margin-top: 2.5rem;
    p {
      margin-bottom: 0;

      &.body-l {
        margin-bottom: 2rem;
      }
    }

    .styled-list {
      @include list;
    }
    .tab-point {
      margin-bottom: 1rem;
      .small-print-headline {
        margin-bottom: 0;
      }
    }
  }
  .col-lg-4 {
    img {
      width: 100%;
    }
  }
}
