@import "../../styles/variables";
.homepage {
  .hero-homepage {
    height: 400px;
    background-image: url("../../../public/assets/Hero/Hero.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    img {
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }

    h2 {
      margin-top: 4.125rem;
    }
  }
  .info-section {
    background-color: var(--bs-orange);
  }

  .col-12 {
    &:nth-of-type(2) {
      margin: 1.5rem 0;
    }
  }
}

@media (min-width: 992px) {
  .homepage {
    .hero-homepage {
      background-image: url("../../../public/assets/Hero/homepage.png");
      align-items: center;
      img {
        width: auto;
      }
      h2 {
        margin: 0;
      }
    }
    .col-12 {
      &:nth-of-type(2) {
        margin: 0;
      }
    }

    .info-section {
      .main-container {
        text-align: center;
      }
    }
  }
}
