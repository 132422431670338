.card-img-overlay {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);

    img {
        width: 100%;
    }
    .card-text {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }
}